import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import {
  DismissCircleFilled,
  DocumentCheckmarkFilled,
  DocumentDismissFilled,
  DocumentErrorFilled,
  DocumentSyncFilled
} from "@fluentui/react-icons";
import { Color, State } from "@/types";
import { Button } from "@mui/material";
import { useMemo } from "react";

const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 45px;
`;

interface Props {
  state: State;
  color: Color;
  onDelete: () => void;
  onCheck: () => void;
}

export default function ActionsAndStatus({ state, color, onDelete, onCheck }: Props) {
  const enabled = useMemo(() => {
    switch (state) {
      case State.Processing:
      case State.Checking:
      case State.Success:
      case State.Fail:
        return true;
      case State.Error:
      default:
        return false;
    }
  }, [state]);

  const icon = useMemo(() => {
    switch (state) {
      case State.Processing:
      case State.Checking:
        return <CircularProgress size={16} />;
      case State.Success:
        return <DocumentCheckmarkFilled />;
      case State.Fail:
        return <DocumentErrorFilled />;
      case State.Error:
        return <DocumentDismissFilled />;
      default:
        return <DocumentSyncFilled />;
    }
  }, [state]);

  const label = useMemo(() => {
    switch (state) {
      case State.Processing:
        return "Gerando checksum";
      case State.Checking:
        return "Validando checksum";
      case State.Success:
        return "Checksum válido";
      case State.Fail:
        return "Checksum não válido";
      case State.Error:
        return "Falha na conexão, tente novamente";
      default:
        return "Validar checksum";
    }
  }, [state]);

  return (
    <Container>
      <Button
        color={color}
        variant="outlined"
        disabled={enabled}
        onClick={(event) => {
          event.preventDefault();
          onCheck();
        }}
        startIcon={icon}
      >
        {label}
      </Button>

      <IconButton
        disabled={state === State.Processing || state === State.Checking}
        onClick={(event) => {
          event.preventDefault();
          onDelete();
        }}
      >
        <DismissCircleFilled />
      </IconButton>
    </Container>
  );
}
